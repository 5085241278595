import { getBlockByName } from "../blocks";
import { ThemeProvider } from "@emotion/react";
import { PageLayout } from "../components/PageLayout";
import Head from "next/head";
import { GET_PUBLIC_PAGE } from "../graphql";
import { useApolloClient } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { NextPage } from "next";

const BlogPage: NextPage = () => {
  const router = useRouter();
  const [pageData, setPageData] = useState<any>();
  const client = useApolloClient();

  useEffect(() => {
    const fetchData = async () => {
      const domain = window.location.host;
      const slug = router.query.slug && router.query.slug[0];
      // const page = context.query.page;

      const { data } = await client.query({
        query: GET_PUBLIC_PAGE,
        variables: {
          key: {
            domain,
            slug: slug || "_home",
          },
        },
      });

      // if (!data?.publicPage) {
      //   context.res.statusCode = 404;
      //   return context.res.end();
      // }

      setPageData(data.publicPage);
    };

    fetchData();
  });

  if (!pageData) return <div />;

  const { page, theme } = pageData;
  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      {page.blocks.length ? (
        <PageLayout branding>
          {page.blocks.map((pageBlock: any, index: number) => {
            const block = getBlockByName(pageBlock.blockName);
            if (!block) return <div />;

            const Element = block.element;
            return <Element {...pageBlock.props} key={index} />;
          })}
        </PageLayout>
      ) : null}
    </ThemeProvider>
  );
};

export default BlogPage;
